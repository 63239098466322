.gabaritListTemplate {
  --color_overflow: var(--color-white);
  --EM-menu-item-fg-active: var(--color_gabaritList-accent);
  --EM-menu-item-fg-hover: var(--color_gabaritList-accent);
  --EM-activeItem-border-bottom-height: 0.125rem;
  --EM-horizontal-overflow-color: var(--color_overflow-light);
  --EM-horizontal-overflow-color-transparent: var(--color_white-0);
  --EM-menu-item-fg: var(--color_gabaritList-item-light-fg);
  --EM-dropdown-bg: var(--color_gabaritList-dropdown-light-bg);
  --EM-dropdown-item-bg-hover: var(--color_gabaritList-dropdown-light-bg-hover);
  --EM-dropdown-item-fg-hover: var(--color_gabaritList-dropdown-light-fg-hover);

  [data-theme~='dark'] & {
    --color_overflow: var(--color-dark);
    --EM-horizontal-overflow-color: var(--color_overflow-dark);
    --EM-horizontal-overflow-color-transparent: var(
      --od-color_darkmode-primary-0
    );
    --EM-menu-item-fg: var(--color_gabaritList-item-dark-fg);
    --EM-dropdown-bg: var(--color_gabaritList-dropdown-dark-bg);
    --EM-dropdown-item-bg-hover: var(
      --color_gabaritList-dropdown-dark-bg-hover
    );
    --EM-dropdown-item-fg-hover: var(
      --color_gabaritList-dropdown-dark-fg-hover
    );
  }

  position: relative;
  z-index: 6; /* Navigation dropdown must be above the cards (and close button for perso cards) */

  &__tabPanel {
    padding-top: 1px; /* Prevents margin-collpasing between Header and Panel */

    &--hidden {
      opacity: 0;
    }
  }

  &__header {
    --padding-for-tv: 1.875rem 5.625rem 1.25rem;
    --padding-for-large-desktop: 1.875rem 3.75rem 1.25rem;
    --padding-for-desktop: 1.875rem 3.75rem 1.25rem;
    --padding-for-tablet: 1.875rem 1.875rem 1.25rem;
    --padding-for-small: 1.875rem 0.9375rem 1.25rem;

    @mixin paddingPages var(--padding-for-large-desktop),
      var(--padding-for-desktop), var(--padding-for-tablet),
      var(--padding-for-small);
    @mixin tvDevice {
      @mixin paddingPages var(--padding-for-tv), var(--padding-for-desktop),
        var(--padding-for-tablet), var(--padding-for-small);
    }
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;

    @media (--mq-from-tablet) {
      flex-direction: row;
    }

    &--action {
      padding: 0;
      margin-right: -0.125rem;
      margin-left: -0.125rem;

      @media (--mq-from-laptop) {
        padding-bottom: 0.625rem;
      }
    }

    &--multiline {
      position: relative;
      display: block;

      img {
        max-width: 6.25rem;
      }
    }

    &--subMenu {
      --padding-for-tv: 1rem 5.625rem 1rem;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0) 100%
      );

      @mixin tvDevice {
        @mixin paddingPages var(--padding-for-tv), var(--padding-for-desktop),
          var(--padding-for-tablet), var(--padding-for-small);
      }
    }
  }
}

.expandableMenuTheme {
  --horizontal-offset: 0.9375rem;
  width: calc(100% + var(--horizontal-offset) * 2);
  margin-left: calc(
    -1 * var(--horizontal-offset)
  ); /* Correct vertical alignment with sibling elements */
  font-size: 1.125rem;

  @mixin tvDevice {
    margin-left: 0;
    --EM-menu-item-fg-active: var(--color_white);
    --EM-menu-item-fg: var(--color_base);
  }
}
