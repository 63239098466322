.gabaritListNav {
  /* empty but needed to be loaded */

  /* TODO: to delete and add on ExpandableMenu instead (featNewEPG)  */
  &--mainList {
    font-family: var(--font_canal);
    text-transform: uppercase;
  }
}

:global {
  .gabaritListNav.tv__focus {
    @mixin tv-focus-button-base;
    border-radius: var(--border-radius-big-outline);

    &:hover {
      color: var(--color_focus-fg) !important;
    }

    [data-theme~='telecomitalia'] & {
      color: var(--color_focus-fg);
      fill: var(--color_focus-fg);
    }
  }
}
