.Stub {
  position: relative;
  z-index: var(--z-index-nav);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10vh 1.25rem;
  text-align: center;

  @media (--mq-from-tablet) {
    width: 30rem;
    margin-right: auto;
    margin-left: auto;
  }

  @mixin tvDevice {
    width: 68.75rem;
    height: 100vh;
  }

  &__image {
    width: 17.5rem;
    height: 17.5rem;
  }

  &__title {
    margin-top: 1.25rem;
    margin-bottom: 0.9375rem;
    font-size: 1.5rem;

    @mixin tvDevice {
      margin-top: 1.5625rem;
      margin-bottom: 1.25rem;
      font-size: 2.9375rem;
    }
  }

  &__text {
    margin-bottom: 0;
    font-size: 1rem;

    @mixin tvDevice {
      font-size: 1.9375rem;
    }
  }

  &__button.Stub__button {
    display: inline-block;
    width: 20rem;
    max-width: 100%;
    padding: 0.5625rem 1.25rem 0.625rem;
    margin-top: 1.875rem;
    font-size: 1.125rem;

    @mixin tvDevice {
      width: 30rem;
      margin-top: 3.125rem;
      font-size: 2.25rem;
    }
  }

  &__button__tvDevice {
    @mixin tvDevice {
      background-color: var(--color_button-tv);
    }
  }
}

:global {
  .Stub .tv__focus {
    @mixin tv-focus-button-base;
  }
}
